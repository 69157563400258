import * as React from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Error } from "@progress/kendo-react-labels";
import ConfirmBox from "./ConfirmBox";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Tooltip } from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

export const CrudButtons = (props) => {
  const { dataItem, DATA_ITEM_KEY, data, orgGridData } = props;
  const inEdit = dataItem.inEdit;
  const isNewItem = dataItem[DATA_ITEM_KEY] === undefined;
  const [visible, setVisible] = React.useState(false);
  const onDeleteData = () => {
    remove(props.dataItem);
    setVisible(!visible);
  };
  const toggleDialog = () => {
    setVisible(!visible);
  };

  const remove = (dataItem) => {
    props.handleDelete(dataItem);
  };

  const add = (dataItem) => {
    console.log(dataItem, "isPrimary");
    dataItem.inEdit = true;
    props.handleAdd(dataItem);
    dataItem.inEdit = false;
    props.setInEdit(null);
    props.setAddDisable(false);
  };

  const update = (dataItem) => {
    props.handleUpdate(dataItem);
    dataItem.inEdit = false;
    props.setInEdit(null);
    props.setAddDisable(false);
  };

  // Local state operations
  const discard = (dataItem) => {
    const newData = [...data];
    newData.splice(0, 1);
    props.setData(newData);
    props.setInEdit(null);
    props.setAddDisable(false);
  };

  const cancel = (dataItem) => {
    // inEdit = false;
    console.log("cancel");
    const originalItem = orgGridData.find(
      (p) => p[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]
    );
    const newData = data.map((item) =>
      item[DATA_ITEM_KEY] === originalItem[DATA_ITEM_KEY] ? originalItem : item
    );
    props.setInEdit(null);
    props.setAddDisable(false);
    console.log(newData, data, "update3");
    props.setData(newData);
  };

  const enterEdit = (dataItem) => {
    let newData =
      data &&
      data.length > 0 &&
      data.map((item) =>
        item[DATA_ITEM_KEY] === dataItem[DATA_ITEM_KEY]
          ? {
              ...item,
              inEdit: true,
            }
          : item
      );
    props.setAddDisable(true);
    console.log(newData, "newData");
    props.setData(newData);
    props.setInEdit(dataItem[DATA_ITEM_KEY]);
  };

  const handleAddPrimary = (dataItem) => {
    props.handleSetPrimary(dataItem.id);
  }

  console.log(data, "datatt2");
  let isSaveEnabled = true;
  let checkValidation =
    dataItem &&
    Object.keys(dataItem).filter(
      (each) => each.includes("Valid") && dataItem[each] !== ""
    );
  if (checkValidation && checkValidation.length > 0) isSaveEnabled = false;
  if((checkValidation && checkValidation.length>0))
    isSaveEnabled = false;
  //remove inEdit & Discontinued to check if no value is present in dataitem
  if(Object.keys(dataItem).length==2 && (inEdit && isNewItem))
    isSaveEnabled = false;
 props.columns.map(each => {if(each.required &&   isSaveEnabled && !dataItem[each.field]) {
  console.log(isSaveEnabled, each.required, !dataItem[each.field], 'isSaveEnabled1111');
  isSaveEnabled = false;
 }})

  console.log(
    isSaveEnabled,
    "isSaveEnabled",
    checkValidation,
    props.addDisable,
    props.addBotton,
    dataItem,
    isNewItem
  );

  return (
    <td className="k-command-cell">
      {props.addBotton && !dataItem.isPrimary && !isNewItem && !inEdit && (
        <Tooltip title="Add Primary" arrow placement="left">
          <IconButton
            style={
              inEdit
                ? isNewItem && isSaveEnabled
                  ? { cursor: "pointer" }
                  : isSaveEnabled
                  ? { cursor: "pointer" }
                  : { cursor: "not-allowed" }
                : props.addDisable
                ? { cursor: "not-allowed" }
                : { cursor: "pointer" }
            }
            onClick={() => handleAddPrimary(dataItem)}
          >
            <PersonAddIcon color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip
        title={inEdit ? (isNewItem ? "Save" : "Update") : "Edit"}
        arrow
        placement="right"
      >
        <IconButton
          onClick={() =>
            inEdit
              ? isNewItem && isSaveEnabled
                ? add(dataItem)
                : isSaveEnabled
                ? update(dataItem)
                : undefined
              : props.addDisable
              ? undefined
              : enterEdit(dataItem)
          }
          style={
            inEdit
              ? isNewItem && isSaveEnabled
                ? { cursor: "pointer" }
                : isSaveEnabled
                ? { cursor: "pointer" }
                : { cursor: "not-allowed" }
              : props.addDisable
              ? { cursor: "not-allowed" }
              : { cursor: "pointer" }
          }
        >
          {inEdit ? (
            isNewItem ? (
              <CheckCircleOutlineIcon color="primary" fontSize="small" />
            ) : (
              <CheckCircleOutlineIcon color="primary" fontSize="small" />
            )
          ) : (
            <EditIcon color="primary" fontSize="small" />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip
        title={
          inEdit
            ? isNewItem
              ? "Discard"
              : "Cancel"
            : !dataItem.isPrimary && "Delete"
        }
        placement="right"
        arrow
      >
        {inEdit ? (
          isNewItem ? (
            <IconButton onClick={() => discard(dataItem)}>
              <CancelOutlinedIcon color="error" fontSize="small" />
            </IconButton>
          ) : (
            <IconButton onClick={() => cancel(dataItem)}>
              <CancelOutlinedIcon color="error" fontSize="small" />
            </IconButton>
          )
        ) : (
          !dataItem.isPrimary && (
            <IconButton
              onClick={() =>
                inEdit
                  ? isNewItem && isSaveEnabled
                    ? toggleDialog()
                    : isSaveEnabled
                    ? toggleDialog()
                    : undefined
                  : props.addDisable
                  ? undefined
                  : toggleDialog()
              }
              style={
                inEdit
                  ? isNewItem && isSaveEnabled
                    ? { cursor: "pointer" }
                    : isSaveEnabled
                    ? { cursor: "pointer" }
                    : { cursor: "not-allowed" }
                  : props.addDisable
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              <DeleteIcon color="error" fontSize="small" />
            </IconButton>
          )
        )}
      </Tooltip>

      {visible && (
        <ConfirmBox
          isOpen={true}
          onSubmitModel={() => onDeleteData()}
          onCloseModel={toggleDialog}
          message={"Are you sure you want to delete User"}
          type="delete"
        />
      )}
    </td>
  );
};

const CustomCell = (props) => {
  const [focused, setFocused] = React.useState(false);
  const anchor = React.useRef(null);
  const isValid = props.dataItem[props.field + "Valid"];
  return props.tdProps ? (
    <>
      <td
        ref={anchor}
        {...props.tdProps}
        style={{
          ...props.tdProps.style,
        }}
        onFocus={() => {
          setFocused(true);
        }}
        onBlur={() => {
          setFocused(false);
        }}
      >
        {React.Children.toArray(props.children).map((child) => {
          console.log(child, "child");
          return React.cloneElement(child, {
            className:
              child.props.className +
              (isValid ? " k-input-solid k-invalid" : ""),
          });
        })}
      </td>
      {props.dataItem.inEdit && focused && (
        <Popup
          anchor={anchor.current}
          show={!!isValid}
          popupClass={"popup-content"}
        >
          <Error>{isValid}</Error>
        </Popup>
      )}
    </>
  ) : null;
};

export const MyInputCustomCell = (props) => (
  <>
    <CustomCell {...props} />
  </>
);
