import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, Select, FormControl, Tooltip } from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import {
  addNewAgreement,
  addPointOfContact,
  deletePointOfContact,
  getAgreementType,
  getAgreementsGridData,
  getCountries,
  getEnduserClassification,
  getProvinces,
  getStates,
  getTechnologyTypes,
  setPrimaryUser,
  updatePointOfContact,
} from "../CommonFunctions/ApiCalls";
import { connect } from "react-redux";
import CommonModel from "../MasterComponent/CommonModel";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { NavLink, useNavigate } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  handleFormValidation,
  requiredValidator,
  viewErrorMessage,
} from "../CommonFunctions/CommonMethods";
import {
  editContact,
  endUserAgreement,
  fields,
  salesAgreement,
} from "../MasterComponent/CommonConstants";
import { toast } from "react-toastify";
import { SetLoadingOverlay } from "../Redux/Action";
import CommonGrid, { EditableGrid } from "../MasterComponent/CommonGrid";
import AddIcon from "@mui/icons-material/Add";
import PersonIcon from "@mui/icons-material/Person";

const DATA_ITEM_KEY_AUTHUSER = "id";
const DATA_ITEM_KEY = "agreementId";

const Home = (props) => {
  const editFieldContact = "inEdit";

  const [open, setOpen] = useState({
    open: false,
    agreementType: null,
    type: null,
  });
  const [agreement, setAgreement] = useState();
  const [rows, setRows] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [state, setState] = useState([]);
  const [euClassification, seteuClassification] = useState([]);
  const [agreementType, setAgreementType] = useState([]);
  const [technology, setTechnology] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isSaveDisable, setIsSaveDisable] = useState(true);
  const [originalAgreement, setOriginalAgreement] = useState();
  const [provinces, setProvinces] = useState();
  const [countries, setCountries] = useState();
  const [agreementTypeFilter, setAgreementTypeFilter] = useState([]);
  const [filter, setFilter] = useState();
  const [contactData, setContactData] = useState([]);
  const [addDisable, setAddDisable] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [newAgreements, setNewAgreements] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    getAllAgreementData();
    if (props.userRole.role === "sales" || props.isAdmin) {
      getAllStates();
      getEUClassifications();
      getAgreementsType();
      getTechnologyType();
      getAllProvinces();
      getAllCountries();
    }
  }, []);

  useEffect(() => {
    if (agreementTypeFilter && agreementTypeFilter.length > 0) {
      let filterType = [...agreementTypeFilter];
      if (!agreement?.technologyId || parseInt(agreement?.technologyId) === 0) {
        filterType = filterType.filter(
          (each) => each.customerType === parseInt(agreement?.customerTypeId)
        );
        setAgreementType(filterType);
      } else {
        filterType = filterType.filter(
          (each) =>
            each.customerType === parseInt(agreement?.customerTypeId) &&
            each.technologyId === agreement?.technologyId
        );
        setAgreementType(filterType);
      }
    }
  }, [
    agreement?.customerTypeId,
    agreement?.technologyId,
    agreement?.agreementTypeId,
  ]);

  useEffect(() => {
    if (open.type === "viewAgreement") {
      let tempAgreement = { ...agreement };
      tempAgreement.customerTypeId = parseInt(tempAgreement.customerTypeId);
      let isDisabled =
        JSON.stringify(tempAgreement) === JSON.stringify(originalAgreement) ||
        errorMessage?.length !== 0 ||
        tempAgreement.stateId === "0";
      setIsSaveDisable(isDisabled);
    } else if (open.type === "agreement") {
      let hasValue = true;
      fields.newAgreement.forEach((element) => {
        if (!agreement[Object.keys(element)[0]]) {
          hasValue = false;
          return;
        }
      });
      setIsSaveDisable(!(hasValue && errorMessage.length === 0));
    }
    console.log(agreement, "agg");
  }, [agreement]);

  useEffect(() => {
    setContactData(agreement?.contacts);
  }, [agreement?.contacts]);

  console.log(agreement, "agreement", open);

  const getAllAgreementData = async () => {
    setGridLoading(true);
    let tempData = [];
    var email = props.userRole.email;
    await getAgreementsGridData(email)
      .then((data) => {
        if (data && data.length > 0) {
          tempData = [...data];
          tempData = tempData.map((each) => {
            let temp = each.contacts.filter((item) => item.isPrimary);
            if (temp && temp.length > 0) {
              each["firstName"] = temp[0].firstName;
              each["lastName"] = temp[0].lastName;
              each["emailAddress"] = temp[0].emailAddress;
              each["phoneNumber"] = temp[0].phoneNumber;
              each["aggType"] = each.agreementType?.type;
            }
            return each;
          });
          console.log("newAgreement", tempData);
          setRows(tempData);
          let newAgreement = tempData.filter(
            (each) => !each.hasSeenProgramDescription
          );
          setNewAgreements(newAgreement);
          if (
            (props.userRole.role === "end-user" ||
              props.userRole.role === "customer") &&
            newAgreement &&
            newAgreement.length > 0
          ) {
            setOpen({ open: true, type: "openPD" });
          }
        }
      })
      .catch((e) => console.log(e, "erro"));
    setGridLoading(false);
    return tempData;
  };

  const getAllStates = async () => {
    await getStates()
      .then((data) => {
        if (data && data.length > 0) {
          setState(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAllProvinces = async () => {
    await getProvinces()
      .then((data) => {
        if (data && data.length > 0) {
          setProvinces(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAllCountries = async () => {
    await getCountries()
      .then((data) => {
        if (data && data.length > 0) {
          setCountries(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getEUClassifications = async () => {
    await getEnduserClassification()
      .then((data) => {
        if (data && data.length > 0) {
          seteuClassification(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getAgreementsType = async () => {
    await getAgreementType()
      .then((data) => {
        if (data && data.length > 0) {
          setAgreementType(data);
          setAgreementTypeFilter(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getTechnologyType = async () => {
    await getTechnologyTypes()
      .then((data) => {
        if (data && data.length > 0) {
          setTechnology(data);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const handleClose = () => {
    setOpen({ open: false, agreementType: null, type: null });
    setAgreement([]);
    setErrorMessage([]);
    setIsDisabled(false);
    setIsSaveDisable(true);
    setContactData([]);
    setAddDisable(false);
  };

  const handleOnChange = (fieldKey, e, actionType) => {
    let updateAgreementDetails = { ...agreement };
    updateAgreementDetails[fieldKey] = e.target.value;
    if (fieldKey === "country") {
      updateAgreementDetails.stateId = "0";
    }
    if (fieldKey === "customerTypeId" || fieldKey === "technologyId") {
      if (updateAgreementDetails["agreementTypeId"]) {
        updateAgreementDetails.agreementTypeId = 0;
      }
      console.log(updateAgreementDetails, "updated");
    }
    if (fieldKey === "agreementTypeId") {
      if (
        updateAgreementDetails.agreementTypeId &&
        parseInt(updateAgreementDetails.agreementTypeId) !== 0
      ) {
        updateAgreementDetails.technologyId = agreementType.filter(
          (each) => each.id === updateAgreementDetails.agreementTypeId
        )[0]["technologyId"];
      }
    }
    setAgreement(updateAgreementDetails);
    var field =
      actionType === "create" ? fields.newAgreement : fields.editAgreement;
    if (field.filter((each) => Object.keys(each)[0] === fieldKey).length > 0) {
      let errors = handleFormValidation(
        fieldKey,
        e.target.value,
        errorMessage,
        updateAgreementDetails
      );
      setErrorMessage(errors);
    }
  };

  const handleAddAuth = (params) => {
    console.log(params.dataItem.contacts, "dataItem");
    setAgreement(params.dataItem);
    setContactData(params.dataItem.contacts);
    setOpen({ open: true, type: "authorizedUser" });
  };

  const handleCreateAgreement = async () => {
    props.SetLoadingOverlay(true);
    let newAgreement = { ...agreement };
    newAgreement.customerTypeId = parseInt(newAgreement.customerTypeId);
    newAgreement.CreatedByEmailAddress = props.userRole.email;
    await addNewAgreement(newAgreement)
      .then(async (result) => {
        console.log(result, result.statusCode, "res");
        if (result && result.statusCode === 200 && result.message !== 0) {
          toast.success("Agreement Added Successfully !!!");
          getAllAgreementData();
          handleClose();
        } else {
          toast.error("Something went wrong please try again later !!!");
          handleClose();
        }
      })
      .catch((e) => {
        toast.error("An Error Occured !!!");
        handleClose();
      });
    props.SetLoadingOverlay(false);
    setAgreement([]);
  };

  const getModelBody = () => {
    if (open.type === "agreement") {
      return (
        <>
          {getInputType("customerTypeId", "type2", null, "create")}
          <div className="grid grid-cols-6 gap-2 m-5">
            <label className="text-size">Title</label>
            <div className="col-span-2">
              {getInputType("title", "type0", null, "create")}
            </div>
            <label className="text-size">
              Email Address<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType("emailAddress", "type0", null, "create")}
            </div>
            <label className="text-size">
              First Name<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType("firstName", "type0", null, "create")}
            </div>
            <label className="text-size">
              Phone Number<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType("phoneNumber", "type0", null, "create")}
            </div>
            <label className="text-size">
              Last Name<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType("lastName", "type0", null, "create")}
            </div>
            <label className="text-size">
              {agreement?.customerTypeId === "1" && "Purchasing"} Customer #
            </label>
            <div className="col-span-2">
              {getInputType("customerNumber", "type0", null, "create")}
            </div>
            <label className="text-size">
              Company Name<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType("companyName", "type0", null, "create")}
            </div>
            <label className="text-size">
              Technology<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType(
                "technologyId",
                "type1",
                {
                  options: technology,
                  id: "id",
                  name: "name",
                },
                "create"
              )}
            </div>
            <label className="text-size">
              Country<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType(
                "country",
                "type1",
                {
                  options: countries,
                  id: "id",
                  name: "name",
                },
                "create"
              )}
            </div>
            <label className="text-size">
              Agreement Type<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-2">
              {getInputType(
                "agreementTypeId",
                "type1",
                {
                  options: agreementType,
                  id: "id",
                  name: "type",
                },
                "create"
              )}
            </div>
            <label className="text-size">
              Address<span className="text-red-500"> *</span>
            </label>
            <div
              className={`${
                agreement?.customerTypeId === "1"
                  ? "col-span-2"
                  : "col-span-5 w-2/5"
              }`}
            >
              {getInputType("address", "type0", null, "create")}
            </div>
            {agreement?.customerTypeId === "1" && (
              <>
                <label className="text-size">Vertical Market</label>
                <div className="col-span-2">
                  {getInputType(
                    "euClassificationId",
                    "type1",
                    {
                      options: euClassification,
                      id: "id",
                      name: "name",
                    },
                    "create"
                  )}
                </div>
              </>
            )}
            <label className="text-size">Address 2</label>
            <div className="col-span-5 w-2/5">
              {getInputType("address2", "type0", null, "create")}
            </div>
            <label className="text-size">
              City<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-5 w-2/5">
              {getInputType("city", "type0", null, "create")}
            </div>
            <label className="text-size">
              {agreement?.country === "CA" ? "Provinse" : "State"}
              <span className="text-red-500"> *</span>
            </label>
            <div className="col-span-5 w-2/5">
              {getInputType(
                "stateId",
                "type1",
                {
                  options: agreement?.country === "CA" ? provinces : state,
                  id: "id",
                  name: "stateName",
                },
                "create"
              )}
            </div>
            <label className="text-size">
              Postal Code<span className="text-red-500"> *</span>
            </label>
            <div className="col-span-5 w-2/5">
              {getInputType("postalCode", "type0", null, "create")}
            </div>
          </div>
        </>
      );
    } else if (open.type === "reviewAgreement") {
      return (
        <>
          <label className="text-lg font-semibold block mt-5 ml-6">
            Click Below to Review
          </label>
          <ul className="ml-28 mt-4 flex flex-col gap-3 w-72">
            <li>
              <RadioButtonUncheckedIcon fontSize="small" />
              <NavLink>
                <span className="hyper-link text-size"> Agreement</span>
              </NavLink>
            </li>
            <li>
              <TaskAltIcon fontSize="small" className="text-green-500" />
              <NavLink>
                <span className="hyper-link text-size"> Accounting Setup</span>
              </NavLink>
            </li>
            <li>
              <TaskAltIcon fontSize="small" className="text-green-500" />
              <NavLink>
                <span className="hyper-link text-size">
                  {" "}
                  MasterKey System Review
                </span>
              </NavLink>
            </li>
            <li>
              <RadioButtonUncheckedIcon fontSize="small" />
              <NavLink>
                <span className="hyper-link text-size">
                  {" "}
                  Engineering Coining Die Approval
                </span>
              </NavLink>
            </li>
            <li>
              <RadioButtonUncheckedIcon fontSize="small" />
              <NavLink>
                <span className="hyper-link text-size"> Recieved PO</span>
              </NavLink>
            </li>
          </ul>
        </>
      );
    } else if (open.type === "authorizedUser") {
      return (
        <>
          <div className="p-5">
            <div className="mb-3">
              <Button
                variant="contained"
                size="small"
                className="button-text-decor"
                startIcon={<AddIcon />}
                onClick={() => addNew()}
                disabled={addDisable}
              >
                Add User
              </Button>
            </div>
            <EditableGrid
              gridLoading={false}
              DATA_ITEM_KEY={DATA_ITEM_KEY_AUTHUSER}
              columns={editContact}
              height="50px"
              rowHeight={20}
              rowData={contactData}
              setData={setContactData}
              itemChange={itemChangeContact}
              editField={editFieldContact}
              isCrud={true}
              orgGridData={agreement?.contacts ? agreement.contacts : []}
              setAddDisable={setAddDisable}
              addDisable={addDisable}
              handleDelete={handleDeletePointOfContact}
              handleAdd={handleAddPointOfContact}
              handleUpdate={handleUpdatePointOfContact}
              handleSetPrimary={handleSetPrimaryUser}
              addBotton={true}
            />
          </div>
        </>
      );
    } else if (open.type === "openPD") {
      return (
        <div className="m-7 text-justify">
          <label>
            You have a new{" "}
            <span className="font-semibold text-lg">
              {newAgreements &&
                newAgreements.length > 0 &&
                newAgreements[0].agreementType.type}
            </span>{" "}
            agreement would you like to open it ?
          </label>
          <div className="flex justify-around m-5">
            <NavLink
              to={`/${props.userRole.role}/program-description/${newAgreements[0]?.id}`}
            >
              <Button
                variant="contained"
                size="small"
                className="caps-text-size"
              >
                YES
              </Button>
            </NavLink>
            <Button
              variant="outlined"
              size="small"
              color="error"
              className="caps-text-size"
              onClick={handleClose}
            >
              NO
            </Button>
          </div>
        </div>
      );
    }
  };

  const getInputType = (fieldkey, type, dropDown = null, actionType = "") => {
    switch (type) {
      case "type0":
        return (
          <>
            <input
              type="text"
              className={`border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-72 text-size ${
                isDisabled && "cursor-not-allowed text-gray-400"
              }`}
              onChange={(e) => handleOnChange(fieldkey, e, actionType)}
              value={agreement && agreement[fieldkey]}
              disabled={isDisabled}
            />
            <span className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errorMessage)}
            </span>
          </>
        );
      case "type1":
        return (
          <>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              sx={{ fontSize: "small" }}
              className={`border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-72 ${
                isDisabled && "notAllowedCursor"
              }`}
              value={
                agreement && agreement[fieldkey] ? agreement[fieldkey] : "0"
              }
              onChange={(e) => {
                handleOnChange(fieldkey, e, actionType);
              }}
              disabled={isDisabled}
            >
              <MenuItem value="0" sx={{ fontSize: "small" }}>
                Please Select...
              </MenuItem>
              {dropDown.options &&
                dropDown.options.length > 0 &&
                dropDown.options.map((data) => {
                  return (
                    <MenuItem
                      value={data[dropDown.id]}
                      sx={{ fontSize: "small" }}
                    >
                      {data[dropDown.name]}
                    </MenuItem>
                  );
                })}
            </Select>
            <span className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errorMessage)?.replace(
                "Enter",
                "Select"
              )}
            </span>
          </>
        );
      case "type2":
        return (
          <FormControl className="w-full">
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              className="justify-center items-center m-1"
              onChange={(e) => {
                handleOnChange(fieldkey, e, actionType);
              }}
              value={
                agreement && agreement[fieldkey] ? agreement[fieldkey] : ""
              }
            >
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={1}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 1 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="End User"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={2}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 2 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="Dealer"
              />
              <FormControlLabel
                sx={{
                  ".MuiFormControlLabel-label": {
                    fontSize: "14px",
                  },
                }}
                value={3}
                control={
                  <Radio
                    size="small"
                    disabled={isDisabled}
                    className={`${
                      parseInt(agreement?.customerTypeId) === 3 &&
                      !isDisabled &&
                      "radio-filled"
                    }`}
                  />
                }
                label="Distributor"
              />
            </RadioGroup>
            <span className="text-xs text-red-500 ml-20">
              {viewErrorMessage(fieldkey, errorMessage)?.replace(
                "Enter",
                "Select"
              )}
            </span>
          </FormControl>
        );
    }
  };

  const handleCreateNewAgreementPopup = () => {
    let updateAgreementDetails = { ...agreement };
    updateAgreementDetails["assaAbloyCompany"] = 1;
    updateAgreementDetails["customerTypeId"] = "1";
    updateAgreementDetails["country"] = "US";
    setAgreement(updateAgreementDetails);
    setOpen({
      open: true,
      agreementType: "new",
      type: "agreement",
    });
  };

  const itemChangeContact = (event) => {
    const field = event.field || "";
    // console.log(newData,"newData", event);
    const newData = contactData.map((item) =>
      item[DATA_ITEM_KEY_AUTHUSER] === event.dataItem[DATA_ITEM_KEY_AUTHUSER]
        ? {
            ...item,
            [field]: event.value,
            [field + "Valid"]: requiredValidator(event.value, event.field),
          }
        : item
    );
    console.log(newData, "newData");
    setContactData(newData);
  };

  const addNew = () => {
    const newDataItem = {
      inEdit: true,
      Discontinued: false,
    };
    setContactData([newDataItem, ...contactData]);
    setAddDisable(true);
  };

  const navigateAgreement = (dataItem) => {
    props.userRole.role === "end-user" || props.userRole.role === "customer"
      ? dataItem.dataItem.hasSeenProgramDescription
        ? navigate(`agreement/${dataItem.dataItem.id}`)
        : navigate(`program-description/${dataItem.dataItem.id}`)
      : navigate(`agreement/${dataItem.dataItem.id}`);
  };

  const handleDeletePointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      id: contactData.id,
      fk_AgreementId: contactData.fk_AgreementId,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: contactData.isPrimary,
    };
    await deletePointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.statusCode === 200 && result.ok) {
          await getAllAgreementData().then((result) => {
            console.log(result, "resss tempData");
            let tempData = result.filter((each) => each.id === agreement.id);
            console.log(tempData, "tempData");
            setAgreement(tempData[0]);
          });
          toast.error("User Deleted Successfully !!!");
        } else {
          toast.error("Please try again later!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleAddPointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      fk_AgreementId: agreement?.id,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: false,
    };
    await addPointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.ok && result.statusCode === 200) {
          await getAllAgreementData().then((result) => {
            console.log(result, "resss tempData");
            let tempData = result.filter((each) => each.id === agreement.id);
            console.log(tempData, "tempData");
            setAgreement(tempData[0]);
          });

          toast.success("New User Added successfully");
        } else {
          toast.error("Unable to add new User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleUpdatePointOfContact = async (contactData) => {
    props.SetLoadingOverlay(true);
    let requestBody = {
      id: contactData.id,
      fk_AgreementId: contactData.fk_AgreementId,
      title: contactData.title,
      firstName: contactData.firstName,
      lastName: contactData.lastName,
      emailAddress: contactData.emailAddress,
      phoneNumber: contactData.phoneNumber,
      isPrimary: contactData.isPrimary,
    };
    await updatePointOfContact(requestBody)
      .then(async (result) => {
        console.log(result, "add point of contact");
        if (result.ok && result.statusCode === 200) {
          await getAllAgreementData().then((result) => {
            console.log(result, "resss tempData");
            let tempData = result.filter((each) => each.id === agreement.id);
            console.log(tempData, "tempData");
            setAgreement(tempData[0]);
          });
          toast.success("User Updated successfully");
        } else {
          toast.error("Unable to update User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
  };

  const handleSetPrimaryUser = async (pocId) => {
    props.SetLoadingOverlay(true);
    await setPrimaryUser(pocId)
      .then(async (result) => {
        console.log(result, "primaryResult");
        if (result && result.message.includes("Updated")) {
          await getAllAgreementData().then((result) => {
            console.log(result, "resss tempData");
            let tempData = result.filter((each) => each.id === agreement.id);
            console.log(tempData, "tempData");
            setAgreement(tempData[0]);
          });
          toast.success("User Updated successfully");
        } else {
          toast.error("Unable to update User!!!");
        }
      })
      .catch((ex) => {
        toast.error(ex);
        console.log(ex, "error");
      });
    props.SetLoadingOverlay(false);
    console.log(pocId, "pocId");
  };

  console.log(agreement, "newupp", rows, contactData);

  const kendoColumns =
    props.userRole.role === "end-user" || props.userRole.role === "customer"
      ? endUserAgreement
      : props.userRole.role === "sales" || props.userRole.role === "internal"
      ? salesAgreement
      : [];

  return (
    <>
      {(props.userRole.role === "sales" || props.isAdmin) && (
        <div className="mb-4">
          <Button
            variant="contained"
            size="small"
            onClick={() => handleCreateNewAgreementPopup()}
            className="caps-text-size"
            startIcon={<PostAddIcon />}
          >
            New Agreement
          </Button>
        </div>
      )}
      <CommonGrid
        gridLoading={gridLoading}
        DATA_ITEM_KEY={DATA_ITEM_KEY}
        rowData={rows}
        columns={kendoColumns}
        filter={filter}
        setFilter={setFilter}
        filterable={true}
        height={(props.userRole.role === "sales" || props.isAdmin) && "50px"}
        buttons={
          props.userRole.role === "end-user"
            ? [
                {
                  button: (
                    <Tooltip title="View Agreement" arrow placement="left">
                      <SearchIcon fontSize="small" color="primary" />
                    </Tooltip>
                  ),
                  onClick: (dataItem) => navigateAgreement(dataItem),
                },
                {
                  button: (
                    <Tooltip title="Add Auth User" arrow placement="right">
                      <PersonIcon fontSize="small" color="primary" />
                    </Tooltip>
                  ),
                  onClick: (dataItem) => handleAddAuth(dataItem),
                },
              ]
            : props.userRole.role === "sales"
            ? [
                {
                  button: (
                    <Tooltip title="View Agreement" arrow placement="right">
                      <SearchIcon fontSize="small" color="primary" />
                    </Tooltip>
                  ),
                  onClick: (dataItem) =>
                    navigate(`view-agreement/${dataItem.dataItem.id}`),
                },
              ]
            : props.userRole.role === "customer"
            ? [
                {
                  button: (
                    <Tooltip title="View Agreement" arrow placement="right">
                      <SearchIcon fontSize="small" color="primary" />
                    </Tooltip>
                  ),
                  onClick: (dataItem) => navigateAgreement(dataItem),
                },
              ]
            : props.userRole.role === "internal"
            ? [
                {
                  button: (
                    <Tooltip title="View Agreement" arrow placement="right">
                      <SearchIcon fontSize="small" color="primary" />
                    </Tooltip>
                  ),
                  onClick: (dataItem) =>
                    navigate(`view-agreement/${dataItem.dataItem.id}`),
                },
              ]
            : []
        }
      />
      <div>
        <CommonModel
          isOpen={open.open}
          onCloseModel={() => handleClose()}
          header={
            open.type === "agreement" || open.type === "openPD"
              ? "New Agreement"
              : open.type === "reviewAgreement"
              ? "Review Agreement"
              : open.type === "selectCompany"
              ? "New Agreement"
              : open.type === "viewAgreement"
              ? "View Agreement"
              : open.type === "authorizedUser"
              ? "Authorized User"
              : ""
          }
          body={getModelBody()}
          size={
            open.type === "reviewAgreement" ||
            open.type === "selectCompany" ||
            open.type === "openPD"
              ? "500px"
              : "1000px"
          }
          onSubmit={() => {
            handleCreateAgreement();
          }}
          footer={{
            isVisible:
              open.type === "selectCompany" ||
              open.type === "viewAgreement" ||
              open.type === "authorizedUser" ||
              open.type === "openPD"
                ? false
                : true,
            isSubmitVisible: open.type === "reviewAgreement" ? false : true,
            iscancelVisible: true,
            isSubmitDisable: isSaveDisable,
          }}
          buttonName={{
            submit: open.type === "agreement" && "Create New Agreement",
            cancel: open.type === "reviewAgreement" ? "Close" : "Cancel",
          }}
          height={
            open.type === "agreement" ||
            open.type === "viewAgreement" ||
            open.type === "authorizedUser"
              ? "85%"
              : open.type === "selectCompany"
              ? "40%"
              : open.type === "reviewAgreement"
              ? "60%"
              : ""
          }
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    userRole: state.userRole,
    isAdmin: state.isAdmin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
